import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import { TOPUP_TABLE_COLUMNS as tableColumns } from '@/constants/topup'
import store from '@/store'
import { apiTopup } from '@/api'
import { useRouter } from '@/@core/utils/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTopupHandle() {
  const { route } = useRouter()

  const refTopupListTable = ref(null)
  const loading = ref(null)
  const searchKeywords = ref(null)
  const totalTopups = ref(0)
  const sizePerPage = ref(40)
  const currentPage = ref(1)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC
  const selectedIds = ref([])

  const meData = computed(() => store.getters['userStore/getMeData'])

  const dataMeta = computed(() => {
    const localItemsCount = refTopupListTable.value
      ? refTopupListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTopups.value,
    }
  })
  const disabledExport = computed(() => selectedIds.value.length === 0)

  const toast = useToast()

  // { paymentStatus, startDate, endDate }
  let filtersParams = {}
  const paymentStatusByRoute = ref(null)
  const entityTypeByRoute = ref(null)

  const fetchTopups = async () => {
    try {
      loading.value = true
      const response = await apiTopup.fetchTopups({
        endDate: filtersParams.endDate ? `${filtersParams.endDate}T23:59:59Z` : null,
        startDate: filtersParams.startDate ? `${filtersParams.startDate}T00:00:00Z` : null,
        paymentStatus: filtersParams?.paymentStatus || paymentStatusByRoute.value || null,
        searchText: searchKeywords.value || undefined,
        sortBy: 'createdAt', // sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: 'DESC', // isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
        entityType: entityTypeByRoute.value || 'BOTH',
      })

      totalTopups.value = response?.data?.total
      return response?.data?.items
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching topups list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return []
    } finally {
      loading.value = false
    }
  }

  const refetchData = ($event = {}) => {
    filtersParams = { ...$event }
    if (refTopupListTable.value) {
      refTopupListTable.value.refresh()
    }
  }

  watch([route], () => {
    const paths = route.value.path.split('/')
    const len = paths.length
    if (len === 6) {
      paymentStatusByRoute.value = paths[len - 1] !== 'list' ? paths[len - 1].toUpperCase() : null
      entityTypeByRoute.value = paths[len - 2] !== 'list' ? paths[len - 2].toUpperCase() : null
    } else {
      paymentStatusByRoute.value = null
      entityTypeByRoute.value = paths[len - 1] !== 'list' ? paths[len - 1].toUpperCase() : null
    }
    refetchData()
  }, { immediate: true })

  const clearFilter = () => {
    searchKeywords.value = null
    refetchData()
  }

  const isChecked = id => selectedIds.value.includes(id)
  const setArray = (array, id) => {
    const index = array ? array.findIndex(element => element === id) : -1
    // eslint-disable-next-line no-unused-expressions
    index >= 0 ? array.splice(index, 1) : array.push(id)

    return array
  }
  const chooseItem = id => {
    selectedIds.value = setArray(selectedIds.value, id)
  }
  const exportTopups = locale => {
    const timeZoneOffset = new Date().getTimezoneOffset()
    store
      .dispatch('app-topup/exportTopups', { language: locale, timeZoneOffset })
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Export successfully',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        console.error({ error })
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error export topups',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc, searchKeywords],
    () => {
      refetchData()
    },
  )

  // for topup confirm manual
  const paymentCode = ref('')
  const paymentAmount = ref(0)
  const paymentType = ref('')
  const evidenceUrl = ref(null)
  const note = ref(null)
  const imgFile = ref(null)
  const imgBlobUrl = ref('')

  const disabledConfirm = computed(() => !note.value)
  //  !imgBlobUrl.value ||

  const canConfirmManual = ({ paymentStatus, recipientId }) => {
    const checkStatus = paymentStatus === 'PENDING_PAYMENT' || paymentStatus === 'CONFIRMING'
    const checkRecipient = recipientId === meData?.value?.agency?.id
    return checkStatus && checkRecipient
  }

  const canDelete = ({ paymentStatus, recipientId }) => {
    const isType = ['KTT', 'KTV'].includes(meData.value.type)
    const checkStatus = paymentStatus === 'PENDING_PAYMENT'
    const checkRecipient = recipientId === meData?.value?.agency?.id
    return isType && checkStatus && checkRecipient
  }

  const setPaymentData = (payCode, payAmount, type) => {
    paymentCode.value = payCode
    paymentAmount.value = payAmount
    paymentType.value = type
  }
  const resetPaymentData = () => {
    setPaymentData('', 0, '')
  }
  const resetEvidenceData = () => {
    evidenceUrl.value = null
    note.value = null
    imgFile.value = null
    imgBlobUrl.value = ''
  }
  function onOpenPopupConfirmManual(payCode, payAmount, type) {
    if (payAmount < 0) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Không thể xác nhận nạp với hoá đơn có số tiền nhỏ hơn 0.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return
    }

    setPaymentData(payCode, payAmount, type)
    this.$bvModal.show('id-topup-confirm-manual')
  }
  async function onImgFileChange(event) {
    if (event.target.value.length) {
      const uploadFile = event.target.files[0]
      this.$refs.refProviderImgFile.syncValue(uploadFile)
      const { valid } = await this.$refs.refProviderImgFile.validate(event)
      if (valid) {
        imgFile.value = uploadFile
        imgBlobUrl.value = URL.createObjectURL(uploadFile)
      }
    } else {
      this.$refs.refProviderImgFile.syncValue(undefined)
      imgFile.value = null
      imgBlobUrl.value = ''
    }
  }
  const uploadEvidenceImage = async () => {
    const formData = new FormData()
    formData.append('file', imgFile.value, imgFile.value.name)

    try {
      const { url } = await store.dispatch(
        'app-topup/uploadEvidenceImage',
        formData,
      )
      evidenceUrl.value = url
    } catch (error) {
      console.error('error', error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error upload evidence image',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      evidenceUrl.value = ''
    }
  }

  const confirmTopupManual = async ({ paymentCode, paymentAmount, autoPayment }) => {
    try {
      const res = await store.dispatch('app-topup/confirmTopupManual', {
        paymentCode,
        paymentAmount,
        autoPayment,
        evidenceUrl: evidenceUrl.value || undefined,
        note: note.value || undefined,
      })
      return res
    } catch (error) {
      console.error('error', error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error confirm topup',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return false
    }
  }

  const deletePopup = async id => {
    loading.value = true
    await apiTopup
      .deleteTopupById(id)
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Delete successfully',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  async function onConfirmManual(event, payCode, payAmount, autoPayment, callback) {
    loading.value = true
    event.preventDefault()
    if (imgFile.value) await uploadEvidenceImage()
    // if (evidenceUrl.value) {
    const result = await confirmTopupManual({
      paymentCode: payCode,
      paymentAmount: Number(payAmount),
      autoPayment,
    })
    if (result?.raw[0]?.paymentStatus === 'DONE') {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Confirm topup successfully',
          icon: 'CoffeeIcon',
          variant: 'success',
        },
      })
      this.$bvModal.hide('id-topup-confirm-manual')
      callback()
      loading.value = false
    }
    loading.value = false
    // }
  }

  return {
    tableColumns,

    refTopupListTable,
    loading,
    searchKeywords,
    totalTopups,
    sizePerPage,
    currentPage,
    sortBy,
    isSortDirDesc,
    dataMeta,
    disabledExport,
    selectedIds,

    fetchTopups,
    refetchData,
    clearFilter,
    isChecked,
    chooseItem,
    exportTopups,

    // for topup confirm manual
    paymentCode,
    paymentAmount,
    paymentType,
    evidenceUrl,
    note,
    imgFile,
    imgBlobUrl,
    disabledConfirm,

    canConfirmManual,
    canDelete,

    resetPaymentData,
    resetEvidenceData,
    onOpenPopupConfirmManual,
    onImgFileChange,
    onConfirmManual,
    deletePopup,
    entityTypeByRoute,
  }
}
